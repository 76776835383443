<template>
  <div
    class="suggested-mapping-popup"
    ref="mappingsPopupRef"
  >
    <div
      class="suggested-mapping-header"
    >
      <div class="main-header">
        <Button
          v-if="subMappingActive"
          variant="tertiary"
          icon="chevron-left"
          icon-only
          @click="exitSubMapping"
        />
        <Heading
          as="h1"
          size="xs"
        >
          {{ popupConfig.title }}
        </Heading>
      </div>
      <div
        v-if="isEventList"
        class="filter-wrapper"
      >
        <FeedSelector
          :feeds="selectorFeeds"
          :selected-feed="eventListSelectedFeed"
          @feed-selected="selectFeed"
        />
      </div>
      <Button
        class="close-button"
        variant="tertiary"
        icon="x"
        icon-only
        @click="closePopup"
      />
    </div>
    <div
      @mouseenter="setHeaderVisibility(true)"
      @mouseleave="setHeaderVisibility(false)"
      class="suggested-mapping-huddle-info-wrapper"
    >
      <div
        class="suggested-mapping-huddle-info"
      >
        <div class="logo-block">
          <icon :name="popupConfig.details.icon" />
        </div>
        <div class="huddle-info-details-wrapper">
          <div class="details-name">
            {{ popupConfig.details.name }}
          </div>
          <div
            v-if="popupConfig.details.additionalInfo"
            class="details-additional"
          >
            {{ popupConfig.details.additionalInfo }}
          </div>
        </div>
      </div>
      <div
        v-if="headerActionsVisible"
        class="suggested-mapping-huddle-actions"
      >
        <Dropdown
          class="more-actions-btn"
          icon="more-dots"
          hide-chevron
          right
          transparent-button
        >
          <DropdownItem
            @click="handleAdditionalActions(mappingActionTypes.COPY_ID)"
            clickable
          >
            <Icon :name="'copy'" />
            <span>Copy ID</span>
          </DropdownItem>
          <DropdownItem
            v-if="selectedFeed.feed !== HUDDLE && isSelectedFeedPrimary"
            @click="handleAdditionalActions(mappingActionTypes.CREATE_NEW_MAPPING)"
            clickable
          >
            <Icon :name="'plus'" />
            <span>Create new {{ labelByType }} item</span>
          </DropdownItem>
        </Dropdown>
        <div
          :class="['copy-confirmation ', { 'active': copyIdMessageActive }]"
        >
          ID copied to the clipboard
        </div>
      </div>
    </div>
    <CreateNewMapping
      v-if="createNewMappingActive && !subMappingActive"
      :data="popupConfig"
      :selected-feed="selectedFeed"
      :mapping-type="mappingType"
      :row-mapping-id="editData.row.mappingId"
      @subMappingActive="subMappingAction"
      @mappingCreated="createNewMapping"
    />
    <div
      v-if="(!createNewMappingActive || subMappingActive) && !exactMappingFound"
      :style="{ width: '100%'}"
    >
      <div
        v-if="mappingType !== mappingTypes.EVENTS_MAPPING"
        class="suggested-mapping-tabs"
      >
        <div
          v-for="tab in suggestedMappingTabs"
          :key="tab.key"
          class="suggested-mapping-tabs-item"
          :class="{ 'active': tab.key === selectedTab }"
          @click="onTabChange(tab)"
        >
          {{ tab.label }}
        </div>
      </div>
      <div
        v-if="selectedTab === 'suggested-results' || mappingType === mappingTypes.EVENTS_MAPPING"
        class="suggested-results"
      >
        <div
          class="advanced-settings-wrapper"
        >
          <div class="advanced-settings-header">
            <div class="advanced-settings-title">
              <div class="title">
                Top results from Huddle database
              </div>
            </div>
            <div
              class="advanced-settings-header-toggle"
            >
              <div
                @click="toggleAdvancedSettings"
              >
                Suggestion settings
              </div>
              <Icon
                :name="'chevron-up'"
                v-if="advancedSettingsVisible"
                @click="toggleAdvancedSettings"
              />
              <Icon
                :name="'chevron-down'"
                v-else
                @click="toggleAdvancedSettings"
              />
            </div>
          </div>
          <div
            class="advanced-settings-body"
            v-if="advancedSettingsVisible"
          >
            <div
              v-if="hasTimeDiff()"
              class="time-diff-wrapper column"
            >
              <div class="time-diff-label">
                Time difference (min):
                <Tooltip :text="'Adjust event start-time matching requirements'">
                  <Icon :name="'info'" />
                </Tooltip>
              </div>
              <div class="time-diff-buttons">
                <div
                  class="time-diff-button"
                  @click="changeTimeDiff('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <div class="mid-time">
                  <input
                    type="number"
                    class="mid-time-input"
                    v-model="filtersData.time"
                  >
                </div>
                <div
                  class="time-diff-button"
                  @click="changeTimeDiff('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              v-if="hasMinSim()"
              :class="['similarity-wrapper column ', { 'align-left one-line': !hasTimeDiff()}]"
            >
              <div class="similarity-label">
                Minimum similarity:
                <Tooltip :text="`Adjust ${labelByType} name matching requirements`">
                  <Icon :name="'info'" />
                </Tooltip>
              </div>
              <div class="similarity-slider-wrapper">
                <div class="similarity-slider">
                  <Slider
                    v-model="filtersData.similarity"
                    :tooltips="false"
                    :lazy="false"
                    :min="0"
                    :max="100"
                  />
                </div>
                <div class="slider-value">
                  {{ filtersData.similarity }}%
                </div>
              </div>
            </div>
            <div class="run-button-wrapper column">
              <div
                :class="['run-button', {'disabled': loadingMapping}]"
                @click="getMappings"
              >
                Run
                <Icon :name="'arrow-right'" />
              </div>
            </div>
          </div>
          <div
            v-if="advancedSettingsVisible"
            class="horizontal-line"
          />
        </div>
        <div
          class="suggested-mappings-wrapper"
          v-if="suggestedMappings.length && !loadingMapping"
        >
          <div
            :class="['suggested-mappings-scroller ', { 'position-unset': suggestedMappings.length === 1}]"
            :style="{ 'height': suggestedMappings.length * 87 + 'px' }"
          >
            <SuggestedMappingDetails
              v-for="(mapping, index) in suggestedMappings"
              :key="mapping.eventId"
              :suggested-mappings-length="suggestedMappings.length"
              :edit-data="editData"
              :index="index"
              :suggested-mapping="mapping"
              :mapping-type="mappingTypeComp"
              :flipped-events="flippedEvents"
              :selected-sport-label="selectedSport"
              :selected-feed="selectedFeed"
              :sub-mapping-active="subMappingActive"
              :sub-mapping-mapping-id="subMappingData?.mappingId || ''"
              :is-event-list="isEventList"
              @viewHistory="openHistory"
              @flipEvents="flipEvents"
              @closePopup="closePopup"
              @subMappingMapped="subMappingMapped"
            />
          </div>
        </div>
        <div
          class="spinner-wrapper"
          v-if="loadingMapping"
        >
          <Spinner />
        </div>
        <div
          v-if="!loadingMapping"
          class="suggested-mappings-footer-action"
        >
          <div class="button-group">
            <div
              v-if="suggestedMappings.length && !loadingMapping"
              @click="loadMoreSuggestions"
              class="load-more-results"
            >
              Load more results
            </div>
          </div>
        </div>
        <div
          v-if="!suggestedMappings.length && !loadingMapping"
          class="no-mappings-message"
        >
          There are no suggestions, try adjusting ‘Suggestion settings’.
        </div>
      </div>
      <MappingsSearch
        v-if="selectedTab === 'search-mappings'"
        :edit-data="editData"
        :mapping-type="mappingType"
        @closePopup="closePopup"
      />
    </div>
    <UnmapMapping
      v-if="exactMappingFound"
      :id="exactMappingFound.id"
      :mapping-id="exactMappingFound.mappingId"
      :feed="exactMappingFound.feed"
      :name="exactMappingFound.extRef"
      :additional-info="popupConfig.details.additionalInfo"
      :sport="selectedSportLabel"
      :mapping-type="mappingType"
      :edit-data="editData"
      @closePopup="closePopup"
    />
    <PreviousMatches
      v-if="suggestedMappings.length && historyOpened"
      :teams="previousMatches"
      @closeHistory="closeHistory"
    />
  </div>
  <div
    :style="{'height': `${pageHeight}px`}"
    class="background-cover"
  />
</template>

<script>
import {
  computed, ref, onMounted, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  find, map, cloneDeep, includes,
} from 'lodash';
import Slider from '@vueform/slider';
import Heading from '@/components/common/Heading';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import Spinner from '@/components/common/Spinner';
import SuggestedMappingDetails from '@/components/mapping/common/SuggestedMappingDetails';
import CreateNewMapping from '@/components/mapping/common/CreateNewMapping';
import PreviousMatches from './PreviousMatches';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import FeedSelector from '@/components/mapping/common/FeedSelector';
import UnmapMapping from './UnmapMapping';
import MappingsSearch from './MappingsSearch';

import {
  mappingTypes,
  mappingActionTypes,
  advancedSettingsTypes,
  createPopupConfig,
  createPayloadForFetchingMapping,
  createMappingInfo,
  copyToClipboard,
  getLabelFromType,
  feedTypes,
  subMappingTypes,
} from '@/services/helpers/mappings-mapper';

export default {
  components: {
    Heading,
    Button,
    Icon,
    Tooltip,
    Slider,
    Spinner,
    SuggestedMappingDetails,
    PreviousMatches,
    Dropdown,
    DropdownItem,
    FeedSelector,
    CreateNewMapping,
    UnmapMapping,
    MappingsSearch,
  },
  props: {
    editData: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    mappingType: {
      required: true,
      type: String,
    },
    isEventList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['closePopup'],
  setup(props, { emit }) {
    const { HUDDLE } = feedTypes;
    const store = useStore();
    const feeds = computed(() => store.getters.allFeeds);
    const primaryFeeds = computed(() => store.getters.mappingPrimaryFeeds);
    const selectorFeeds = computed(() => map(feeds.value, ({ feed }) => feed));
    const selectedFeed = computed(() => ({ feed: store.getters.mappingSelectedFeed }));
    const isSelectedFeedPrimary = computed(() => includes(primaryFeeds.value, selectedFeed.value?.feed));
    const selectedSportLabel = computed(() => store.getters.mappingSelectedSportLabel);
    const eventListSelectedFeed = ref((feeds.value ? find(feeds.value, { feed: 'lsports' }) : {})?.feed ?? '');
    const editData = ref(props.editData);
    const createNewMappingActive = ref(false);
    const subMappingActive = ref(false);
    const subMappingData = ref({});
    const subMappingType = ref({});
    const advancedSettingsVisible = ref(false);
    const headerActionsVisible = ref(false);
    const mappingsPopupRef = ref(null);
    const loadingMapping = ref(false);
    const flippedEvents = ref(false);
    const historyOpened = ref(false);
    const selectedMapping = ref({});
    const copyIdMessageActive = ref(false);
    const selectedSport = ref(selectedSportLabel.value);
    const filtersData = ref({
      similarity: 20,
      time: 20,
      rank: 3,
    });
    const popupConfig = ref({});
    const suggestedMappingTabs = computed(() => [
      {
        key: 'suggested-results',
        label: 'Suggested results',
      },
      {
        key: 'search-mappings',
        label: 'Search mappings',
      },
    ]);
    const selectedTab = ref(suggestedMappingTabs.value[0]?.key);
    const onTabChange = (tab) => {
      selectedTab.value = tab.key;
    };

    const mappingTypeComp = computed(() => (subMappingActive.value ? subMappingData.value.mappingType : props.mappingType));
    const suggestedMappings = computed(() => {
      switch (props.mappingType) {
      case mappingTypes.COMPETITORS_MAPPING:
        return store.getters.competitorSuggestedMappings;
      case mappingTypes.REGIONS_MAPPING:
        return store.getters.regionSuggestedMappings;
      case mappingTypes.COMPETITIONS_MAPPING:
        return store.getters.competitionSuggestedMappings;
      case mappingTypes.PLAYERS_MAPPING:
        return store.getters.playerSuggestedMappings;
      case mappingTypes.EVENTS_MAPPING:
        return map(
          store.getters.eventSuggestedMappings, (event) => {
            const noMatchCompetitorsFound = find(
              event.mapping.competitorMappings, (comp) => comp.mappingType !== 'EXACT_MATCH',
            );
            return {
              ...event,
              competitorMapping: !!noMatchCompetitorsFound,
            };
          },
        );
      default: return [];
      }
    });

    const exactMappingFound = computed(() => {
      if (selectedFeed.value.feed === 'huddle') {
        const foundMapping = find(
          props.editData.row.mappings,
          (mapping) => mapping.mappingType === 'EXACT_MATCH' && mapping.feed === props.editData.feed,
        );
        return foundMapping && {
          ...foundMapping,
          id: props.editData.row.id,
        };
      }

      if (props.editData.row.mappingType !== 'EXACT_MATCH') return null;

      return {
        id: props.editData.row.mappedEntity.eventId,
        extId: props.editData.row.id,
        extRef: props.editData.row.name,
        feed: props.editData.feed,
        mappingId: props.editData.row.mappingId,
        mappingType: props.editData.row.mappingType,
      };
    });

    const mappingInfo = computed(() => createMappingInfo({ props, mappingTypeText: popupConfig.value.mappingTypeText }));
    const pageHeight = computed(() => document.body.clientHeight + (document.getElementById('app').offsetTop * -1));
    const labelByType = computed(() => getLabelFromType(mappingTypeComp.value));

    const getMappings = () => {
      if (loadingMapping.value) return;
      loadingMapping.value = true;
      const propsCopy = cloneDeep({
        ...props,
        selectedSportLabel: selectedSportLabel.value,
      });
      if (props.isEventList) {
        propsCopy.eventListSelectedFeed = { feed: eventListSelectedFeed.value };
        propsCopy.selectedSportLabel = props.editData.sportLabel;
      }

      if (subMappingActive.value) {
        propsCopy.mappingType = subMappingData.value.mappingType;
        propsCopy.editData.row.mappingId = subMappingData.value.mappingId;
      }
      const { data, url } = createPayloadForFetchingMapping({ props: propsCopy, filtersData });

      switch (props.mappingType) {
      case mappingTypes.EVENTS_MAPPING:
        flippedEvents.value = false;
        store.dispatch('getEventSuggestedMappings', { data, url })
          .finally(() => {
            loadingMapping.value = false;
          });
        break;
      case mappingTypes.COMPETITORS_MAPPING:
        store.dispatch('getCompetitorSuggestedMappings', { data, url })
          .finally(() => {
            loadingMapping.value = false;
          });
        break;
      case mappingTypes.REGIONS_MAPPING:
        store.dispatch('getRegionSuggestedMappings', { data, url })
          .finally(() => {
            loadingMapping.value = false;
          });
        break;
      case mappingTypes.COMPETITIONS_MAPPING:
        store.dispatch('getCompetitionSuggestedMappings', { data, url })
          .finally(() => {
            loadingMapping.value = false;
          });
        break;
      case mappingTypes.PLAYERS_MAPPING:
        store.dispatch('getPlayerSuggestedMappings', { data, url })
          .finally(() => {
            loadingMapping.value = false;
          });
        break;

      default: break;
      }
    };
    const previousMatches = computed(() => {
      if (!selectedMapping.value?.mapping) return [];
      switch (props.mappingType) {
      case mappingTypes.COMPETITORS_MAPPING:
        return [
          {
            teamName: selectedMapping.value.mapping.extRef,
            previousOpponents: selectedMapping.value.mapping.previousOpponents,
          },
        ];
      case mappingTypes.EVENTS_MAPPING:
        const foundPreviousMatches = find(
          selectedMapping.value.mapping.competitorMappings, (comp) => comp.previousOpponents?.length,
        );
        if (foundPreviousMatches) {
          return map(selectedMapping.value.mapping.competitorMappings, (comp) => (
            {
              teamName: comp.extRef,
              previousOpponents: comp.previousOpponents,
            }));
        }
        return [];
      case mappingTypes.REGIONS_MAPPING:
        return [
          {
            teamName: selectedMapping.value.mapping.extRef,
            previousOpponents: selectedMapping.value.mapping.previousCompetitions,
          },
        ];
      case mappingTypes.COMPETITIONS_MAPPING:
        return [
          {
            teamName: selectedMapping.value.mapping.extRef,
            previousOpponents: selectedMapping.value.mapping.previousCompetitions,
          },
        ];
      case mappingTypes.PLAYERS_MAPPING:
        return [
          {
            teamName: selectedMapping.value.mapping.extRef,
            playerMapping: true,
            previousOpponents: selectedMapping.value.mapping.previousAppearances,
          },
        ];
      default:
        return [];
      }
    });

    const openHistory = (mapping) => {
      selectedMapping.value = mapping;
      historyOpened.value = true;
    };
    const setHeaderVisibility = (visibility) => { headerActionsVisible.value = visibility; };

    const flipEvents = () => { flippedEvents.value = !flippedEvents.value; };

    const toggleAdvancedSettings = () => {
      advancedSettingsVisible.value = !advancedSettingsVisible.value;
    };

    const closeHistory = () => {
      historyOpened.value = false;
    };

    const changeTimeDiff = (operation) => {
      if (operation === '+') {
        filtersData.value.time += 1;
        return;
      }
      if (!filtersData.value.time) return;
      filtersData.value.time -= 1;
    };

    const hasTimeDiff = () => popupConfig.value.advancedFilters.includes(advancedSettingsTypes.TIME_DIFF);
    const hasMinSim = () => popupConfig.value.advancedFilters.includes(advancedSettingsTypes.MIN_SIM);

    const closePopup = (message) => {
      switch (props.mappingType) {
      case mappingTypes.COMPETITORS_MAPPING:
        store.dispatch('saveCompetitorSuggestedMappings', []);
        break;
      case mappingTypes.EVENTS_MAPPING:
        store.dispatch('saveEventSuggestedMappings', []);
        break;
      case mappingTypes.REGIONS_MAPPING:
        store.dispatch('saveRegionSuggestedMappings', []);
        break;
      case mappingTypes.COMPETITIONS_MAPPING:
        store.dispatch('saveCompetitionSuggestedMappings', []);
        break;
      case mappingTypes.PLAYERS_MAPPING:
        store.dispatch('savePlayerSuggestedMappings', []);
        break;

      default: break;
      }

      emit('closePopup', message);
    };

    const showNotificationMessage = (mappedFrom) => {
      const message = `Successfully created from ‘${mappedFrom || selectedFeed.value.feed}’ database.`;
      store.dispatch('addNotification', {
        message,
        type: 'success',
        duration: 5000,
      });
    };

    const subMappingMapped = () => {
      subMappingActive.value = false;

      const {
        REGION,
        COMPETITION,
        HOME_TEAM,
        AWAY_TEAM,
      } = subMappingTypes;
      const editDataCopy = cloneDeep(editData.value);
      switch (subMappingType.value) {
      case REGION:
        const { region } = editDataCopy.row;
        region.mappingType = 'EXACT_MATCH';
        break;
      case COMPETITION:
        const { competition } = editDataCopy.row;
        competition.mappingType = 'EXACT_MATCH';
        break;
      case HOME_TEAM:
        const { homeTeam } = editDataCopy.row;
        homeTeam.mappingType = 'EXACT_MATCH';
        break;
      case AWAY_TEAM:
        const { awayTeam } = editDataCopy.row;
        awayTeam.mappingType = 'EXACT_MATCH';
        break;
      default:
        break;
      }
      editData.value = editDataCopy;
      popupConfig.value = createPopupConfig({
        editData: editData.value,
        mappingType: props.mappingType,
        selectedFeed: selectedFeed.value.feed || HUDDLE,
        isEventList: props.isEventList,
      });
    };

    const resolveMappingSuccess = () => {
      if (subMappingActive.value) {
        subMappingMapped();
      } else {
        emit('closePopup', 'reload');
      }
      showNotificationMessage();
    };

    const createCompetitorMapping = () => {
      const mappingRequest = {
        mappingId: subMappingActive.value ? subMappingData.value.mappingId : editData.value.row.mappingId,
        sport: selectedSportLabel.value,
      };
      store.dispatch('createCompetitorMapping', mappingRequest)
        .then(() => {
          resolveMappingSuccess();
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const createRegionMapping = () => {
      const mappingRequest = {
        mappingId: subMappingActive.value ? subMappingData.value.mappingId : editData.value.row.mappingId,
      };
      store.dispatch('createRegionMapping', mappingRequest)
        .then(() => {
          resolveMappingSuccess();
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const createPlayerMapping = () => {
      const mappingRequest = {
        mappingId: subMappingActive.value ? subMappingData.value.mappingId : editData.value.row.mappingId,
        sport: selectedSportLabel.value,
      };
      store.dispatch('createPlayerMapping', mappingRequest)
        .then(() => {
          resolveMappingSuccess();
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const createCompetitionMapping = () => {
      const mappingRequest = {
        mappingId: subMappingActive.value ? subMappingData.value.mappingId : editData.value.row.mappingId,
      };
      store.dispatch('createCompetitionMapping', mappingRequest)
        .then(() => {
          resolveMappingSuccess();
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const createEventMapping = () => {
      const eventMappingRequest = {
        mappingId: subMappingActive.value ? subMappingData.value.mappingId : editData.value.row.mappingId,
        competitorsMappings: [],
      };
      store.dispatch('createEventCompetitorMapping', eventMappingRequest)
        .then(() => {
          resolveMappingSuccess();
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const createNewMapping = () => {
      switch (mappingTypeComp.value) {
      case mappingTypes.COMPETITORS_MAPPING:
        createCompetitorMapping();
        break;
      case mappingTypes.EVENTS_MAPPING:
        if (createNewMappingActive.value) {
          createEventMapping();
        } else {
          createNewMappingActive.value = true;
        }
        break;
      case mappingTypes.REGIONS_MAPPING:
        createRegionMapping();
        break;
      case mappingTypes.COMPETITIONS_MAPPING:
        if (createNewMappingActive.value) {
          createCompetitionMapping();
        } else {
          createNewMappingActive.value = true;
        }
        break;
      case mappingTypes.PLAYERS_MAPPING:
        createPlayerMapping();
        break;

      default: break;
      }
    };

    const handleAdditionalActions = (action) => {
      const { COPY_ID, CREATE_NEW_MAPPING } = mappingActionTypes;
      switch (action) {
      case COPY_ID:
        copyToClipboard(editData.value.row.id);
        copyIdMessageActive.value = true;
        headerActionsVisible.value = true;
        const timeout = setTimeout(() => {
          copyIdMessageActive.value = false;
          clearTimeout(timeout);
        }, 3000);
        break;
      case CREATE_NEW_MAPPING:
        createNewMapping();
        headerActionsVisible.value = false;
        break;
      default:
        break;
      }
    };

    onClickOutside(mappingsPopupRef, () => {
      emit('closePopup');
    });

    const loadMoreSuggestions = () => {
      if (suggestedMappings.value.length === filtersData.value.rank) {
        filtersData.value = { ...filtersData.value, rank: filtersData.value.rank + 3 };
      }
      getMappings();
    };
    getMappings();

    popupConfig.value = createPopupConfig({
      editData: editData.value,
      mappingType: props.mappingType,
      selectedFeed: selectedFeed.value.feed || HUDDLE,
      isEventList: props.isEventList,
    });

    const scrollTop = window.scrollY;
    (function disableScrollWhenModalIsOpen() {
      const scrollContainer = document.getElementById('app');
      scrollContainer.style.position = 'fixed';
      scrollContainer.style.top = `-${scrollTop}px`;
    }());

    const selectFeed = (feed) => {
      eventListSelectedFeed.value = feed;
      getMappings();
    };

    const subMappingAction = (subMapping) => {
      subMappingData.value = subMapping.data;
      subMappingType.value = subMapping.type;
      subMappingActive.value = true;
      const subMappingEditData = {
        feed: subMapping.data.feed,
        row: {
          id: subMapping.data.extId,
          name: subMapping.data.extRef,
        },
      };
      if (subMapping.data.regionMapping) {
        subMappingEditData.row.region = subMapping.data.regionMapping;
      }
      popupConfig.value = createPopupConfig({
        editData: subMappingEditData,
        mappingType: subMapping.data.mappingType,
        selectedFeed: selectedFeed.value.feed || HUDDLE,
        isEventList: props.isEventList,
      });
      getMappings();
    };

    const exitSubMapping = () => {
      subMappingActive.value = false;
      subMappingData.value = null;

      popupConfig.value = createPopupConfig({
        editData: editData.value,
        mappingType: props.mappingType,
        selectedFeed: selectedFeed.value.feed || HUDDLE,
        isEventList: props.isEventList,
      });
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        emit('closePopup');
      }
    };
    onMounted(() => {
      if (!exactMappingFound.value) { document.addEventListener('keydown', onEscEventListener); }
    });
    onBeforeUnmount(() => {
      if (!exactMappingFound.value) { document.removeEventListener('keydown', onEscEventListener); }
    });

    return {
      popupConfig,
      suggestedMappings,
      closePopup,
      mappingInfo,
      mappingsPopupRef,
      advancedSettingsVisible,
      toggleAdvancedSettings,
      changeTimeDiff,
      getMappings,
      loadingMapping,
      filtersData,
      advancedSettingsTypes,
      pageHeight,
      loadMoreSuggestions,
      closeHistory,
      historyOpened,
      previousMatches,
      openHistory,
      flipEvents,
      mappingActionTypes,
      handleAdditionalActions,
      copyIdMessageActive,
      labelByType,
      flippedEvents,
      selectedSport,
      hasTimeDiff,
      hasMinSim,
      setHeaderVisibility,
      headerActionsVisible,
      feeds,
      selectorFeeds,
      selectedFeed,
      selectedSportLabel,
      eventListSelectedFeed,
      selectFeed,
      createNewMappingActive,
      HUDDLE,
      subMappingAction,
      subMappingActive,
      exitSubMapping,
      mappingTypeComp,
      subMappingMapped,
      subMappingData,
      createNewMapping,
      exactMappingFound,
      isSelectedFeedPrimary,
      onTabChange,
      suggestedMappingTabs,
      selectedTab,
      mappingTypes,
    };
  },
  beforeUnmount() {
    (function enableScrollWhenModalIsClosed() {
      const scrollContainer = document.getElementById('app');
      const scrollY = (scrollContainer.offsetTop * -1);
      scrollContainer.style.position = '';
      scrollContainer.style.top = '';
      window.scrollTo({ top: scrollY });
    }());
  },
};
</script>

<style lang="scss">
@import '@vueform/slider/themes/default.scss';

.suggested-mapping-popup {
  position: fixed;
  top: 20%;
  right: 0;
  left: 0;
  margin: auto;
  min-height: 200px;
  width: 720px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  align-items: center;
  padding: 32px;
  box-sizing: border-box;
  z-index: $modalZIndex;

  .suggested-mapping-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 12px;

    .main-header {
      display: flex;
      align-items: center;
      height: 30px;
      line-height: 30px;
      font-family: 'Poppins';
      font-size: 20px;
      color: #191414;
      font-weight: 600;

      .button {
        margin-right: 4px;
      }
    }

    .filter-wrapper {
      height: 33px;
      position: relative;
      display: flex;
      align-items: center;
      margin-left: -4px;
      margin-top: 10px;
    }

    .updated-info {
      font-size: 12px;
      color: #A9A9A9;
    }

    .close-button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .suggested-mapping-huddle-info-wrapper {
    width: 100%;
    height: 62px;
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &.exact-mapping {
      background: #FFFFFF;
      border: 0;
    }

    .suggested-mapping-huddle-info {
      display: flex;
    }
    .suggested-mapping-huddle-actions {
      display: flex;
      align-items: center;
      position: relative;

      .more-actions-btn .icon {
        stroke: #000;
        margin-right: 10px;
      }

      .copy-confirmation {
        display: none;
        min-width: 170px;
        text-align: center;
        background-color: #191414;
        color: #FFF;
        font-size: 12px;
        font-family: 'Rubik';
        line-height: 14px;
        padding: 8px 12px;
        border-radius: 4px;
        position: absolute;
        right: -30px;
        bottom: -10px;

        &.active {
          display: inline-block;
        }
      }
    }

    .logo-block {
      width: 52px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      &.exact-mapping {
        img {
          height: 24px;
          width: 24px;
        }
      }
    }

    .huddle-info-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .details-name, .details-additional {
        height: 17px;
        line-height: 17px;
        color: #191414;

        span {
          color: #A9A9A9;
        }
      }

      .details-additional {
        color: #A9A9A9;
        margin-top: 4px;
      }
    }
  }

  .suggested-mapping-tabs {
    display: flex;
    height: 48px;
    padding: 24px 0 0 0;
    font-size: 14px;
    line-height: 16px;
    border-bottom: 2px solid $gray400;
    position: relative;

    .suggested-mapping-tabs-item {
      width: 100%;
      padding: 0 8px 8px 8px;
      margin-bottom: -2px;
      cursor: pointer;
      text-align: center;

      &.active {
        border-bottom: 2px solid $brandPrimary500;
      }
    }
  }

  .advanced-settings-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 20px;

    .advanced-settings-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 48px;
      align-items: center;
      padding-right: 16px;
      margin: 16px 0 24px 0;

      &.exact-mapping {
        margin-bottom: 12px
      }

      .advanced-settings-title {

        .title {
          line-height: 24px;
          font-family: 'Poppins';
          font-size: 14px;
          color: #191414;
          font-weight: 800;
        }

        .subtitle {
          line-height: 14.22px;
          font-family: 'Rubik';
          font-size: 12px;
          color: #a9a9a9;
          font-weight: 400;
        }
      }

      .advanced-settings-header-toggle {
        display: flex;
        font-size: 12px;
        cursor: pointer;
      }

      .icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        cursor: pointer;

        svg {
          stroke: #191414;
        }
      }
    }

    .advanced-settings-body {
      height: 70px;
      width: 100%;
      display: flex;
      padding: 0 28px;
      justify-content: space-between;
      user-select: none;

      .column {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        height: 100%;
      }

      .time-diff-wrapper {

        .time-diff-label {
          display: flex;
          height: 18px;
          align-items: center;
          margin-bottom: 6px;

          .tooltip {
            height: 100%;
            margin-left: 8px;

            .tooltip-element {
              height: 18px;
              width: 18px;
              display: flex;

              .icon {
                height: 18px;
                width: 18px;
              }
            }

            .tooltip-text {
              padding: 8px;
              white-space: unset;
              height: unset;
              width: 172px;
            }
          }
        }

        .time-diff-buttons {
          height: 33px;
          border: 1px solid #CDCDCD;
          border-radius: 4px;
          display: flex;
          width: fit-content;

          .time-diff-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 33px;
            cursor: pointer;

            .icon {
              width: 14px;
              height: 14px;

              svg {
                stroke: #191414;
              }
            }
          }

          .mid-time {
            width: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-left: 1px solid #CDCDCD;
            border-right: 1px solid #CDCDCD;

            // Hiding arrows from input number
            .mid-time-input::-webkit-outer-spin-button,
            .mid-time-input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            .mid-time-input {
              display: inline-block;
              height: 100%;
              width: 100%;
              text-align: center;
              // Hiding arrows - mozilla
              -moz-appearance: textfield;

              &:focus {
                box-shadow: $textInputFocusBoxShadow;
                outline: none;
              }
            }
          }
        }
      }

      .similarity-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.align-left {
          align-items: flex-start;
        }
        &.one-line {
          flex-direction: row;
          align-items: center;
        }

        &.one-line .similarity-label {
          width: 170px;
          margin-bottom: 0;
        }

        .similarity-label {
          display: flex;
          height: 18px;
          align-items: center;
          margin-bottom: 18px;

          .tooltip {
            margin-left: 8px;

            .tooltip-element {
              height: 18px;
              width: 18px;
              display: flex;

              .icon {
                height: 18px;
                width: 18px;
              }
            }

            .tooltip-text {
              padding: 8px;
              white-space: unset;
              height: unset;
              width: 172px;
            }
          }
        }

        .similarity-slider-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          left: 22px;
          width: 200px;

          .similarity-slider {
            width: 146px;
            margin-right: 8px;

            --slider-height: 2px;
            --slider-bg: #A9A9A9;
            --slider-handle-bg: #191414;
            --slider-connect-bg: #191414;
            --slider-handle-width: 12px;
            --slider-handle-height: 12px;
            --slider-handle-shadow: none;
            --slider-handle-shadow-active: none;
          }

        }

        &.align-left .similarity-slider-wrapper{
          left: 0;
        }
      }

      .run-button-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .run-button {
          height: 33px;
          background: #003C3C;
          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid #003C3C;
          padding: 0 12px;
          color: #fff;
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon {
            margin-left: 4px;
            height: 14px;
            width: 14px;

            svg {
              stroke: #fff;
              color: #fff;

              path {
                stroke: #fff;
                stroke-width: 4px;
              }
            }
          }

          &.disabled {
            opacity: 0.6;
            cursor: default;
          }
        }
      }
    }
    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #F0F0F0;
      margin-top: 20px;

      &.exact-mapping {
        margin-top: 0;
      }
    }
  }

  .suggested-mappings-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    .suggested-mappings-scroller {
      max-height: 261px; // size of three mappings suggestions
      overflow-y: auto;
    }

    .suggested-mappings-scroller.position-unset {
      position: unset;
    }

  }

  .spinner-wrapper {
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .suggested-mappings-footer-action {
    display: flex;
    justify-content: center;
    padding-top: 12px;

    .load-more-results{
      font-family: 'Rubik';
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      display: inline-block;
      padding: 8px 12px;
      cursor: pointer;
    }
  }
  .no-mappings-message {
    font-size: 14px;
    color: #A9A9A9;
    font-weight: 400;
    line-height: 16px;
  }
}
.background-cover {
  position: absolute;
  background-color: rgba(#000, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: $modalBackgroundZIndex;
  left: 0;
  top: -69px;
}

.unmap-button-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 12px;

  .unmap-button {
    height: 33px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #FAFAFA;
    }

    .icon {
      margin-right: 4px;
    }
  }
}

</style>
