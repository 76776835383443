<template>
  <div class="mappings-search-root">
    <div class="mappings-search-title">
      Search results from Huddle database
    </div>
    <div class="mappings-search-header">
      <Dropdown
        :label="selectedSearchByOption.label"
      >
        <DropdownItem
          v-for="option in searchOptions"
          :key="option.value"
          clickable
          @click="onSearchByChange(option)"
        >
          {{ option.label }}
        </DropdownItem>
      </Dropdown>
      <TextInput
        class="mappings-search-field"
        :model-value="mappingSearchValue"
        icon="search"
        :placeholder="selectedSearchByOption.label"
        small
        @update:modelValue="onSearch"
      />
    </div>
    <div class="mapping-search-content">
      <Spinner v-if="searchResultsLoading" />
      <EmptyState
        v-if="!searchResults.length && !searchResultsLoading"
        class="empty-state"
        :message="emptyStateMessage"
      />
      <div
        v-if="searchResults.length && !searchResultsLoading"
        class="result-items-root"
      >
        <MappingsSearchResultItem
          v-for="item in searchResults"
          :key="item.id"
          :item="item"
          @onMapResult="mapResult"
        />
      </div>
    </div>
    <div
      v-if="showLoadMoreResultsBtn"
      class="mapping-search-actions"
    >
      <Button
        :loading="searchResultsLoading"
        @click="loadMoreResults"
      >
        Load more results
      </Button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  debounce, map, capitalize,
  get,
} from 'lodash';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import TextInput from '@/components/common/TextInput';
import EmptyState from '@/components/common/EmptyState';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';
import MappingsSearchResultItem from './MappingSearchResultItem';
import { labelByMappingType } from '@/services/helpers/mappings-mapper';

export default {
  emits: ['closePopup'],
  components: {
    Dropdown,
    DropdownItem,
    TextInput,
    EmptyState,
    Spinner,
    MappingsSearchResultItem,
    Button,
  },
  props: {
    editData: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    mappingType: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const selectedSportLabel = computed(() => store.getters.mappingSelectedSportLabel);
    const selectedFeed = computed(() => store.getters.mappingSelectedFeed);
    const mappingSearchValue = ref('');
    const searchOptions = ref([
      { label: 'Search by name', value: 'search-by-name' },
      { label: 'Search by ID', value: 'search-by-id' },
    ]);
    const searchResultsLoading = ref(false);
    const searchResults = ref([]);
    const emptyStateMessage = computed(() => (
      mappingSearchValue.value
        ? 'No results found'
        : 'Search for a mapping by entering name or ID'
    ));
    const resultsPerSearch = ref(3);
    const selectedSearchByOption = ref(searchOptions.value[0]);
    const showLoadMoreResultsBtn = computed(() => searchResults.value.length === resultsPerSearch.value
      && selectedSearchByOption.value.value === 'search-by-name');
    const onSearchByChange = (option) => {
      selectedSearchByOption.value = option;
      searchResults.value = [];
      resultsPerSearch.value = 3;
      mappingSearchValue.value = '';
    };

    const mapSearchResults = (res) => map(res, (item) => {
      let itemMapped = {};
      if (selectedFeed.value === 'huddle') {
        itemMapped = {
          id: item.extId,
          label: item.extRef,
          [`${labelByMappingType[props.mappingType]}Id`]: props.editData.row.id,
          mappingId: item.mappingId,
          sport: selectedSportLabel.value,
        };
        return itemMapped;
      }
      let label = get(item, `${labelByMappingType[props.mappingType]}Name`);
      if (props.mappingType === 'PLAYERS_MAPPING') {
        label = `${get(item.personalInfo, 'name')}`;
      }
      if (props.mappingType === 'COMPETITORS_MAPPING') {
        label = `${get(item, 'name')}`;
      }
      return {
        id: get(item, `${labelByMappingType[props.mappingType]}Id`),
        label,
        mappingId: props.editData.row.mappingId,
        [`${labelByMappingType[props.mappingType]}Id`]: get(item, `${labelByMappingType[props.mappingType]}Id`),
        sport: selectedSportLabel.value,
      };
    });

    const fetchSearchResults = () => {
      if (!mappingSearchValue.value) {
        searchResults.value = [];
        resultsPerSearch.value = 3;
        return;
      }
      const storeAction = selectedSearchByOption.value.value === 'search-by-id'
        ? 'searchMappingById'
        : 'searchMappingByName';
      searchResultsLoading.value = true;
      store.dispatch(storeAction, {
        searchValue: mappingSearchValue.value,
        mappingType: props.mappingType,
        sport: selectedSportLabel.value,
        feed: props.editData.feed,
        selectedFeed: selectedFeed.value,
        resultsPerSearch: resultsPerSearch.value,
      }).then((res) => {
        searchResults.value = mapSearchResults(
          selectedSearchByOption.value.value === 'search-by-id'
            ? [res]
            : res,
        );
      }).finally(() => {
        searchResultsLoading.value = false;
      });
    };

    const onSearch = debounce((value) => {
      mappingSearchValue.value = value;
      fetchSearchResults();
    }, 500);

    const mapResult = (item) => {
      const storeAction = `approve${capitalize(labelByMappingType[props.mappingType])}Mapping`;
      store.dispatch(storeAction, item).then(() => {
        emit('closePopup', 'reload');
      });
    };

    const loadMoreResults = () => {
      resultsPerSearch.value += 3;
      fetchSearchResults();
    };

    return {
      mappingSearchValue,
      searchOptions,
      selectedSearchByOption,
      searchResults,
      emptyStateMessage,
      searchResultsLoading,
      showLoadMoreResultsBtn,
      onSearchByChange,
      onSearch,
      mapResult,
      loadMoreResults,
    };
  },
};
</script>

<style lang="scss">
.mappings-search-root {
  .mappings-search-title {
    font-size: 14px;
    font-weight: 600;
    padding-top: 26px;
    font-family: "Poppins";
  }
  .mappings-search-header {
    width: 100%;
    display: flex;
    gap: 4px;
    padding: 8px 0 0 0;
    .mappings-search-field {
      width: 100%;
    }
  }
  .mapping-search-content {
    height: 240px;
    overflow-y: auto;
    .empty-state {
      max-width: 304px;
      display: flex;
      justify-self: center;
      padding: 24px;
    }
  }
  .mapping-search-actions {
    display: flex;
    justify-content: center;
    padding: 16px 0 0 0;
  }
}
</style>
