<template>
  <div class="mapping-search-result-item-root">
    <div class="mapping-search-result-item-label">
      {{ item.label }}
    </div>
    <div
      class="mapping-search-result-item-actions"
    >
      <Button
        class="map-result-btn"
        variant="primary"
        @click="mapResult"
      >
        Map this result
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';

export default {
  emits: ['onMapResult'],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    Button,
  },
  setup(props, { emit }) {
    const mapResult = () => {
      emit('onMapResult', props.item);
    };

    return {
      mapResult,
    };
  },
};
</script>

<style lang="scss">
.mapping-search-result-item-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 12px;
  min-height: 75px;
  cursor: pointer;

  &:hover {
    background-color: $gray300;
    .mapping-search-result-item-actions {
      display: block;
    }
  }

  .mapping-search-result-item-actions {
    display: none;
  }
}
</style>
